import React from 'react';
import '../styles/news-lists.css';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';

// Components
import Label from './label';

const NewsLists = ({ newsLists }) => {
  const renderNewsPath = (locale, slug) => {
    const path = `/news/${slug}`;
    switch (locale) {
      case 'en':
        return `/en${path}`;
      case 'zh':
        return path;
      default:
        return null;
    }
  };
  const renderTagPath = (locale, tag) => {
    const path = `/news/tag/${tag}`;
    switch (locale) {
      case 'en':
        return `/en${path}`;
      case 'zh':
        return path;
      default:
        return null;
    }
  };
  return (
    <ul className="NewsLists">
      {newsLists &&
        newsLists.map(
          ({
            node: {
              frontmatter: {
                locale,
                date,
                title,
                tags,
                featured: {
                  childImageSharp: { fluid },
                },
              },
              fields: { slug },
              id,
              excerpt,
            },
          }) => (
            <li className="NewsListItem" key={id}>
              <Row>
                <Col lg={5}>
                  <div className="NewsListItem__img">{fluid && <Img fluid={fluid} />}</div>
                </Col>
                <Col lg={7}>
                  <div className="NewsListItem__meta">
                    <h4 className="NewsListItem__meta--title">
                      <Link className="NewsListItem__meta--link" to={renderNewsPath(locale, slug)}>
                        {title}
                      </Link>
                    </h4>
                    <span className="NewsListItem__meta--date">{date}</span>
                    <div className="NewsListItem__meta--divider" />
                    <p className="NewsListItem__meta--content">{excerpt}</p>
                  </div>
                  <div className="NewsListItem__label">
                    {tags &&
                      tags.map((tag) => (
                        <Link to={renderTagPath(locale, tag)} key={tag}>
                          <Label label={tag} />
                        </Link>
                      ))}
                  </div>
                </Col>
              </Row>
            </li>
          )
        )}
    </ul>
  );
};

export default NewsLists;
