import React from 'react';
import { graphql } from 'gatsby';

import News from '../../components/pages/news';

export default (props) => <News {...props} />;

export const news = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/news/" }, frontmatter: { locale: { eq: "en" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            locale
            title
            tags
            featured {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          excerpt
        }
      }
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
