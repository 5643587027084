import React, { useState } from 'react';
import '../../styles/pages/news.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';
import NewsLists from '../news-lists';
import Sidebar from '../sidebar';
import Header from '../header';

const News = (props) => {
  const [query, setQuery] = useState('');
  const {
    data: {
      allMarkdownRemark: { edges },
    },
    pageContext: { tag, langKey },
  } = props;
  const filterdLists = edges.filter((edge) => {
    const { title } = edge.node.frontmatter;
    const searchQuery = query.toLowerCase();
    return title.toLowerCase().includes(searchQuery) || edge.node.excerpt.toLowerCase().includes(searchQuery);
  });

  return (
    <Layout>
      <div className="News">
        <Container>
          {!tag && langKey === 'en' && <Header title="News &amp; Updates" />}
          {!tag && langKey === 'zh' && <Header title="最新消息" />}
          {tag && <Header title={langKey === 'en' ? `Find match with ${tag} tag` : `符合${tag}`} />}
          <Row>
            <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 8 }} lg={{ order: 1, span: 9 }}>
              <NewsLists newsLists={filterdLists} />
            </Col>
            <Col
              xs={{ order: 1, span: 12 }}
              md={{ order: 2, span: 4 }}
              lg={{ order: 2, span: 3 }}
              style={{ marginTop: '15px' }}
            >
              <input
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
                style={{
                  width: '100%',
                  border: '1px solid rgba(0,0,0,.15)',
                  background: 'transparent',
                  padding: '4px',
                  marginBottom: '8px',
                }}
              />
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default News;
